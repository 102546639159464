import React from "react"
import Layout from '../components/Layout'
import SubscribeBlock from '../components/SubscribeBlock'

export default function SubscribePage() {
    return (
        <Layout>
            <SubscribeBlock />
        </Layout>
    )
}
